import { createStore } from 'vuex'
   
import account from './account';
import getRoute from './getRoute';
import appInterface from './appInterface';
import snackBar from './snackBar';
import preloader from './preloader';
import dialog from './dialog';
import callcenter from './page/analytics/callcenter';
import current_status_callcenter from './page/current_status/current_status_callcenter';
import current_status_base from './page/current_status/current_status_base'; 
import result_period_callcenter from './page/result_period/result_period_callcenter';
import result_period_base from './page/result_period/result_period_base'; 
import base from './page/analytics/base';
import analytics_optional from './page/analytics/analytics_optional';
import expenses_input_data from './page/expenses/expenses_input_data';
import expenses_budget from './page/expenses/expenses_budget';
import expenses_accounting from './page/expenses/expenses_accounting';
import expenses_result_period from './page/expenses/expenses_result_period';
import fot_result from './page/fot/fot_result';
import fot_statement from './page/fot/fot_statement';
import ebitda_project from './page/ebitda/ebitda_project';
import warehouse_lists from './page/warehouse/warehouse_lists';
import warehouse_entry from './page/warehouse/warehouse_entry';
import warehouse_entry_card from './page/warehouse/warehouse_entry_card';
import warehouse_remains_card from './page/warehouse/warehouse_remains_card';
import warehouse_orders from './page/warehouse/warehouse_orders';
import warehouse_order_card from './page/warehouse/warehouse_order_card';
import warehouse_write_offs from './page/warehouse/warehouse_write_offs';
import warehouse_product_card from './page/warehouse/warehouse_product_card';
import warehouse_reports from './page/warehouse/warehouse_reports';
import warehouse_workshop from './page/warehouse/warehouse_workshop';
import warehouse_min_remains from './page/warehouse/warehouse_min_remains';
 
import sales_pf_current from './page/sales_plan_fact/sales_pf_current';
import sales_pf_plan from './page/sales_plan_fact/sales_pf_plan';
import sales_pf_input from './page/sales_plan_fact/sales_pf_input';
 
import email_surveys from './page/marketing/email_surveys';
import email_analytics from './page/marketing/email_analytics';
import track_analytic_utm from './page/marketing/track_analytic_utm';
import track_analytic_report from './page/marketing/track_analytic_report';

import publications_networks_public from './page/marketing/publications_networks_public';
import publications_networks_create from './page/marketing/publications_networks_create';
import publications_networks_delay from './page/marketing/publications_networks_delay';
import publications_networks_published from './page/marketing/publications_networks_published';

export default createStore({ 
  getters:{},
  mutations: {},
  actions: {},
  modules: { 
    account,  
    getRoute,
    appInterface,
    snackBar,
    dialog,
    preloader,
    callcenter,
    current_status_callcenter,
    current_status_base,
    result_period_callcenter,
    result_period_base,
    base,
    analytics_optional,
    expenses_input_data,
    expenses_budget,
    expenses_accounting,
    expenses_result_period,
    fot_result,
    fot_statement,
    ebitda_project,
    sales_pf_current,
    sales_pf_plan,
    sales_pf_input,
    warehouse_lists,
    warehouse_entry,
    warehouse_entry_card,
    warehouse_orders,
    warehouse_remains_card,
    warehouse_order_card,
    warehouse_write_offs,
    warehouse_product_card,
    warehouse_reports,
    warehouse_workshop,
    warehouse_min_remains,
    email_surveys,
    email_analytics,
    track_analytic_utm,
    track_analytic_report,
    publications_networks_public,
    publications_networks_create,
    publications_networks_delay,
    publications_networks_published
  },
  strict: process.env.NODE_ENV !== 'production'
})
